<template>
  <div>

    <el-form :inline="true">
      <el-form-item>
        <el-input
            v-model="searchForm.usEmail"
            placeholder="用户邮箱"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-input
            v-model="searchForm.recommendedCode"
            placeholder="用户推荐码"
            clearable
        >
        </el-input>
      </el-form-item>

      <el-form-item>
        <el-button @click="getUserList">搜索</el-button>
      </el-form-item>
      &nbsp; &nbsp;
      <el-form-item>
          <el-button type="primary" @click="SClose(1)" >新增</el-button>
      </el-form-item>
<!--      <el-form-item>
        <el-popconfirm title="这是确定批量删除吗？" @confirm="delHandle(null)">
          <el-button type="danger" slot="reference" :disabled="delBtlStatu" v-if="hasAuth('sys:user:delete')">批量删除</el-button>
        </el-popconfirm>
      </el-form-item>-->
    </el-form>
<!--    <el-form :inline="true">
      <el-collapse v-model="activeNames" @change="handleChange">
&lt;!&ndash;        <el-collapse-item title="一致性 Consistency" name="1">
          <div>与现实生活一致：与现实生活的流程、逻辑保持一致，遵循用户习惯的语言和概念；</div>
          <div>在界面中一致：所有的元素和结构需保持一致，比如：设计样式、图标和文本、元素的位置等。</div>
        </el-collapse-item>
        &ndash;&gt;

        <el-collapse-item title="统计" name="2">

          <div>

            <el-descriptions  title="数据统计" :column="7" :size="size">

              <el-descriptions-item label="注册用户(当天)">{{this.tableDataT[0].usEmail}}人</el-descriptions-item>
              <el-descriptions-item label="开卡用户(当天)">{{this.tableDataT[0].cardStatus}}人</el-descriptions-item>
              <el-descriptions-item label="USDT充值(当天)">{{this.tableDataT[0].amountT}} USDT</el-descriptions-item>
              <el-descriptions-item label="人民币充值(当天)">{{this.tableDataT[0].cnyT}} CNY</el-descriptions-item>
              <el-descriptions-item label="兑换充提手续费(当天)">{{this.tableDataT[0].totalComm}} USD</el-descriptions-item>
              <el-descriptions-item label="卡充提手续费(当天)">{{this.tableDataT[0].chargeComm}} USD</el-descriptions-item>
              <el-descriptions-item label="开卡手续费(当天)">{{this.tableDataT[0].cardComm}} USD</el-descriptions-item>

              <el-descriptions-item label="注册用户(总合)">{{this.tableDataT[1].usEmail}}人</el-descriptions-item>
              <el-descriptions-item label="开卡用户(总合)">{{this.tableDataT[1].cardStatus}}人</el-descriptions-item>
              <el-descriptions-item label="USDT充值(总合)">{{this.tableDataT[1].amountT}} USDT</el-descriptions-item>
              <el-descriptions-item label="人民币充值(总合)">{{this.tableDataT[1].cnyT}} CNY</el-descriptions-item>
              <el-descriptions-item label="兑换充提手续费(总合)">{{this.tableDataT[1].totalComm}} USD</el-descriptions-item>
              <el-descriptions-item label="卡充提手续费(总合)">{{this.tableDataT[1].chargeComm}} USD</el-descriptions-item>
              <el-descriptions-item label="开卡手续费(总合)">{{this.tableDataT[1].cardComm}} USD</el-descriptions-item>


              <el-descriptions-item label="用户余额(总合)">{{this.tableDataT[1].amount}} USDT</el-descriptions-item>
              <el-descriptions-item label="用户余额(总合)">{{this.tableDataT[1].usd}} USD</el-descriptions-item>
              <el-descriptions-item label="用户余额(总合)">{{this.tableDataT[1].cny}} CNY</el-descriptions-item>
              <el-descriptions-item label="用户卡余额(总合)">{{this.tableDataT[1].spare3}} USD</el-descriptions-item>

            </el-descriptions>

          </div>

        </el-collapse-item>


      </el-collapse>

    </el-form>-->

    <el-table
        ref="multipleTable"
        :data="tableData"
        tooltip-effect="dark"
        style="width: 100%"
        @cell-dblclick
        @cell-dblclick="rowClick"
        border
        stripe
        @selection-change="handleSelectionChange">

      <el-table-column
          type="selection"
          width="55">
      </el-table-column>
      <el-table-column
          prop="id"
          label="id"
          width="70">
      </el-table-column>


      <!---->
      <el-table-column
          prop="usEmail"
          label="用户邮箱"
          width="260">
      </el-table-column>

      <!---->
      <el-table-column
          prop="amount"
          label="Usdt"
          width="85">
      </el-table-column>

      <el-table-column
          prop="usd"
          label="Usd"
          width="85">
      </el-table-column>
      <el-table-column
          prop="cny"
          label="CNY"
          width="85">
      </el-table-column>

      <!---->



      <el-table-column
          prop="totalCommission"
          label="佣金总合"
          width="78">
      </el-table-column>

      <el-table-column
          prop="totalComm"
          label="兑换充提手续费"
          width="119">
      </el-table-column>

      <el-table-column
          prop="chargeComm"
          label="卡充提手续费"
          width="105">
      </el-table-column>

      <el-table-column
          prop="cardComm"
          label="开卡手续费"
          width="95">
      </el-table-column>

      <el-table-column
          prop="freezeUsdt"
          label="提U冻结余额"
          width="103">
      </el-table-column>

      <el-table-column
          prop="freezeComm"
          label="提U手续费"
          width="90">
      </el-table-column>


      <el-table-column
          prop="recommendedCode"
          label="推荐码"
          width="75">
      </el-table-column>


      <el-table-column
          prop="isKyc"
          label="是否KYC"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.isKyc === '0'" type="danger">否</el-tag>
          <el-tag size="small" v-else-if="scope.row.isKyc === '1'" type="success">是</el-tag>
          <el-tag size="small" v-else-if="scope.row.isKyc === '2'" type="primary">审核中</el-tag>
        </template>
      </el-table-column>
      <el-table-column
          prop="cardStatus"
          label="是否开卡"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.cardStatus === '0'" type="danger">否</el-tag>
          <el-tag size="small" v-else-if="scope.row.cardStatus === '1'" type="success">是</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="isStatus"
          label="是否停用"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.isStatus === '0'" type="success">启用</el-tag>
          <el-tag size="small" v-else-if="scope.row.isStatus === '1'" type="danger">停用</el-tag>
          <el-tag size="small" v-else-if="scope.row.isStatus === '2'" type="danger">冻结</el-tag>
        </template>
      </el-table-column>


      <el-table-column
          prop="isPayPasswrod"
          label="支付密码"
          width="80">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.isPayPasswrod === '0'" type="danger">未设置</el-tag>
          <el-tag size="small" v-else-if="scope.row.isPayPasswrod === '1'" type="success">已设置</el-tag>
        </template>
      </el-table-column>


      <el-table-column
          prop="isPayPasswrod"
          label="trc20激活"
          width="90">
        <template slot-scope="scope">
          <el-tag size="small" v-if="scope.row.trc20Ava === '0'" type="danger">未激活</el-tag>
          <el-tag size="small" v-else-if="scope.row.trc20Ava === '1'" type="success">已激活</el-tag>
        </template>
      </el-table-column>

      <el-table-column
          prop="trc20"
          label="Trc20"
          width="313">
      </el-table-column>
<!--      <el-table-column
          prop="dateRegistered"
          label="注册日期"
          width="165">
      </el-table-column>-->
      <el-table-column
          prop="dateRegistered"
          label="注册日期"
          width="165">
        <template #default="{row}">
          {{ row.dateRegistered.year }}-{{ row.dateRegistered.nano }}{{ row.dateRegistered.monthValue }}-{{ row.dateRegistered.dayOfMonth }} {{ row.dateRegistered.hour }}:{{ row.dateRegistered.minute }}:{{ row.dateRegistered.second }}
        </template>
      </el-table-column>

      <el-table-column
          prop="icon"
          width="260px"
          label="操作">

        <template slot-scope="scope">

          <el-button type="text" @click="editHandle(scope.row.id)">修改</el-button>
<!--          <el-divider direction="vertical"></el-divider>

          <template>
            <el-popconfirm title="这是一段内容确定删除吗？" @confirm="delHandle(scope.row.id)">
              <el-button type="text" slot="reference">删除</el-button>
            </el-popconfirm>
          </template>-->

        </template>
      </el-table-column>


    </el-table>


    <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        layout="->,total, sizes, prev, pager, next, jumper"
        :page-sizes="[14, 20, 50, 100]"
        :current-page="current"
        :page-size="size"
        :total="total">
    </el-pagination>

    <el-dialog
        title="新增用户"
        :visible.sync="sdialogVisible"
        width="800px"
        :before-close="shandleClose">

      <el-form :model="seditForm" :rules="seditFormRules" ref="seditForm">
        <el-row>
          <el-col :span="12"><div class="grid-content bg-purple">
            <el-form-item label="用户邮箱"  prop="usEmail" label-width="100px">
              <el-input v-model="seditForm.usEmail" autocomplete="off" ></el-input>
            </el-form-item>

            <el-form-item label="cny"  prop="cny" label-width="100px">
              <el-input v-model="seditForm.cny" autocomplete="off" ></el-input>
            </el-form-item>


          </div></el-col>
          <el-col :span="12"><div class="grid-content bg-purple-light">

            <el-form-item label="用户密码"  prop="pass" label-width="100px">
              <el-input v-model="seditForm.pass" autocomplete="off" ></el-input>
            </el-form-item>

            <el-form-item label="推荐码"  prop="recommendedCode" label-width="100px">
              <el-input v-model="seditForm.recommendedCode" autocomplete="off" ></el-input>
            </el-form-item>

          </div></el-col>
        </el-row>

      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="sresetForm('seditForm')">取 消</el-button>
        <el-button type="primary" @click="ssubmitForm('seditForm')">确 定</el-button>
      </div>
    </el-dialog>

    <el-dialog
        title="用户修改"
        :visible.sync="dialogVisible"
        width="800px"
        :before-close="handleClose">

      <el-form :model="editForm" :rules="editFormRules" ref="editForm">
        <el-row>
          <el-col :span="12"><div class="grid-content bg-purple">
            <el-form-item label="用户邮箱"  prop="usEmail" label-width="100px">
              <el-input v-model="editForm.usEmail" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="Usdt"  prop="amount" label-width="100px">
              <el-input v-model="editForm.amount" autocomplete="off" ></el-input>
            </el-form-item>

            <el-form-item label="Usd"  prop="usd" label-width="100px">
              <el-input v-model="editForm.usd" autocomplete="off" ></el-input>
            </el-form-item>
            <el-form-item label="cny"  prop="cny" label-width="100px">
              <el-input v-model="editForm.cny" autocomplete="off" ></el-input>
            </el-form-item>

            <el-form-item label="提U冻结余额"  prop="freezeComm" label-width="100px">
              <el-input v-model="editForm.freezeUsdt" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="提U手续费"  prop="freezeComm" label-width="100px">
              <el-input v-model="editForm.freezeComm" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>



            <el-form-item label="注册日期"  prop="dateRegistered" label-width="100px">
              <el-input v-model="editForm.dateRegistered" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

          </div></el-col>
          <el-col :span="12"><div class="grid-content bg-purple-light">

            <el-form-item label="佣金总合"  prop="totalCommission" label-width="100px">
              <el-input v-model="editForm.totalCommission" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="兑换充提手费"  prop="totalComm" label-width="100px">
              <el-input v-model="editForm.totalComm" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="卡充提手续费"  prop="charge_Comm" label-width="100px">
              <el-input v-model="editForm.chargeComm" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="开卡手续费"  prop="cardComm" label-width="100px">
              <el-input v-model="editForm.cardComm" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>
            <el-form-item label="推荐码"  prop="recommendedCode" label-width="100px">
              <el-input v-model="editForm.recommendedCode" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

            <el-form-item label="trc20"  prop="trc20" label-width="100px">
              <el-input v-model="editForm.trc20" autocomplete="off" :disabled="true"></el-input>
            </el-form-item>

          </div></el-col>
        </el-row>

        <el-form-item label="是否KYC"  prop="isStatus" label-width="100px">
          <el-radio-group v-model="editForm.isKyc">
            <el-radio :label="'1'">是</el-radio>
            <el-radio :label="'0'">否</el-radio>
            <el-radio :label="'2'">审核中</el-radio>
          </el-radio-group>
        </el-form-item>

        <el-form-item label="是否停用"  prop="isStatus" label-width="100px">
          <el-radio-group v-model="editForm.isStatus">
            <el-radio :label="'0'">启用</el-radio>
            <el-radio :label="'1'">停用 (用户不可登录)</el-radio>
            <el-radio :label="'2'">冻结 (用户不可操作USDT和卡充值提现操作)</el-radio>
          </el-radio-group>
        </el-form-item>



      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('editForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('editForm')">确 定</el-button>
      </div>
    </el-dialog>



    <!-- 分配权限对话框 -->
    <el-dialog title="分配角色" :visible.sync="roleDialogFormVisible" width="600px">

      <el-form :model="roleForm">
        <el-tree
            :data="roleTreeData"
            show-checkbox
            ref="roleTree"
            :check-strictly=checkStrictly
            node-key="id"
            :default-expand-all=true
            :props="defaultProps">
        </el-tree>
      </el-form>

      <div slot="footer" class="dialog-footer">
        <el-button @click="roleDialogFormVisible=false">取 消</el-button>
        <el-button type="primary" @click="submitRoleHandle('roleForm')">确 定</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import { rsaEncode, aesEncode, aesDecode, getAesKeyByApp } from "@/assets/js/aesRsaUtils.js";
const publicKey = "MIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAi2PYopFxE/QgyBHjxjkhcalBHyg4UAFBYdV09LyCOCA/Xb/knor5LlPcSl1ykuUl041yDoKPU52oTcRJA2gZ4SWfXWvatVE4OHf3ULL87+87XWqjNblLowk4TkFbimW/yNs/gSAqnlIaiNcZ0TtiSrf8zRsTvdijSby0hJNqgjblUrsVEZ02lhFoJ+6P6sYUVr8VHXxcfTaH8fyEukU6N56LfIsNgfolAiG1wtFZmMqTd+a12jfho3SjJDfiZxCQ6W88lNUgObb3/W/y70rId2UgUXzB4LhspgbjsjJuYY/skfiYspb9N18viSwdho007w1K4xSz0Tpeatvo2woyYQIDAQAB";


export default {
  name: "Role",
  data() {
    return {
      like: true,
      value1: 4154.564,
      value3: 2222,
      title: '今年的增长',
      searchForm: {},
      activeNames: ['1'],
      delBtlStatu: true,

      total: 0,
      size: 14,
      current: 1,

      usEmail: '',
      recommendedCode: '',
      dialogVisible: false,
      sdialogVisible: false,
      editForm: {

      },
      seditForm: {
        pass:'aa123456',
        cny:'0.0'

      },

      tableData: [],
      tableDataT: [],
      editFormRules: {
       /* accountNumber: [
          {required: true, message: '请输入iosId', trigger: 'blur'}
        ],
        password: [
          {required: true, message: '请输入密码', trigger: 'blur'}
        ],
        secretProtection: [
          {required: true, message: '请输入密保', trigger: 'blur'}
        ]*/
      },
      seditFormRules: {
        /* accountNumber: [
           {required: true, message: '请输入iosId', trigger: 'blur'}
         ],
         password: [
           {required: true, message: '请输入密码', trigger: 'blur'}
         ],
         secretProtection: [
           {required: true, message: '请输入密保', trigger: 'blur'}
         ]*/
      },

      multipleSelection: [],

      roleDialogFormVisible: false,
      defaultProps: {
        children: 'children',
        label: 'name'
      },
      roleForm: {},
      roleTreeData:  [],
      treeCheckedKeys: [],
      checkStrictly: true

    }
  },
  created() {
    this.getUserList()

    this.$axios.get("/sys/roleMGQZHP/list").then(res => {
      this.roleTreeData = res.data.data.records
    })
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    rowClick(row,column,event) {
      this.editHandle(row.id)
      console.log(row);///获取该行的所有数据
      console.log(column);
      console.log(event);
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach(row => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },
    handleSelectionChange(val) {
      console.log("勾选")
      console.log(val)
      this.multipleSelection = val;

      this.delBtlStatu = val.length == 0
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
      this.size = val
      this.getUserList()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
      this.current = val
      this.getUserList()
    },

    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.dialogVisible = false
      this.editForm = {}
    },

    sresetForm(formName) {
      this.$refs[formName].resetFields();
      this.sdialogVisible = false
      this.seditForm = {}
    },
    handleClose() {
      this.resetForm('editForm')
    },
    shandleClose() {
      this.sresetForm('seditForm')
    },
    SClose(vla) {

      if(vla==1){
        this.seditForm.pass='aa123456';
        this.seditForm.cny='0.0';
      }



      this.sdialogVisible = true;

    },
    getUserList() {
      const { key, mathKey } = getAesKeyByApp();

      var ids = {  usEmail: this.searchForm.usEmail,
        recommendedCode: this.searchForm.recommendedCode,
        current: this.current,
        size: this.size}


      this.$axios.get("/card/thick/listS", {
        params: {

          key: rsaEncode(mathKey, publicKey),
          data: aesEncode(JSON.stringify(ids), key) + "",
          current: this.current,
          size: this.size
        }
      }).then(res => {

        const  jsonData = JSON.parse(res.data.data.countId)
        //console.log(JSON.parse(aesDecode(jsonData.data, key)));
        const  aesData = JSON.parse(aesDecode(jsonData.data, key))
        //console.log(aesData)


        //console.log("records  =      ==="+res.data.data.records)
        this.tableData = aesData;
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total

       /* this.tableData = res.data.data.records
        this.size = res.data.data.size
        this.current = res.data.data.current
        this.total = res.data.data.total*/

        //this.tableDataT=JSON.parse(res.data.data.countId)


      })
    },

    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/card/thick/' + (this.editForm.id?'updateS' : 'save'), this.editForm)
              .then(res => {

                this.$message({
                  showClose: true,
                  message: '恭喜你，操作成功',
                  type: 'success',
                  onClose:() => {
                    this.getUserList()
                  }
                });

                this.dialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    ssubmitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          //alert((this.editForm.id?'update' : 'save'))
          this.$axios.post('/card/user/' + (this.seditForm.id?'updateS' : 'saveS'), this.seditForm)
              .then(res => {


               // alert(res.data.code)
                if(res.data.code == 200){
                  this.$message({
                    showClose: true,
                    message: '恭喜你，操作成功',
                    type: 'success',
                    onClose:() => {
                      this.getUserList()
                    }
                  });
                }
                this.sdialogVisible = false
              })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    editHandle(id) {
      this.$axios.get('/card/user/info/' + id).then(res => {
        this.editForm = res.data.data

        this.dialogVisible = true
      })
    },
    delHandle(id) {

      var ids = []

      if (id) {
        ids.push(id)
      } else {
        this.multipleSelection.forEach(row => {
          ids.push(row.id)
        })
      }
      console.log(ids)
      //alert(ids);


      this.$axios.post("/sale/account/delete", ids).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });
      })
    },

    roleHandle (id) {
      this.roleDialogFormVisible = true

      this.$axios.get('/sys/userWQKKDUXHAL/info/' + id).then(res => {
        this.roleForm = res.data.data

        let roleIds = []
        res.data.data.sysRoles.forEach(row => {
          roleIds.push(row.id)
        })

        this.$refs.roleTree.setCheckedKeys(roleIds)
      })
    },
    submitRoleHandle(formName) {
      var roleIds = this.$refs.roleTree.getCheckedKeys()

      console.log(roleIds)

      this.$axios.post('/sys/userWQKKDUXHAL/role/' + this.roleForm.id, roleIds).then(res => {
        this.$message({
          showClose: true,
          message: '恭喜你，操作成功',
          type: 'success',
          onClose:() => {
            this.getUserList()
          }
        });

        this.roleDialogFormVisible = false
      })
    },
    repassHandle(id, username) {

      this.$confirm('将重置用户【' + username + '】的密码, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.$axios.post("/sys/userWQKKDUXHAL/repass", id).then(res => {
          this.$message({
            showClose: true,
            message: '恭喜你，操作成功',
            type: 'success',
            onClose: () => {
            }
          });
        })
      })
    }
  }
}
</script>

<style scoped>


.like {
  cursor: pointer;
  font-size: 25px;
  display: inline-block;
}
</style>

